import React from 'react';
import PropTypes from 'prop-types';
import { TimelineMax, Expo } from 'gsap';
import cx from 'classnames';
import styles from './ProjectTitle.module.scss';
import { SplitText } from '../../vendor/gsap/SplitText';
class ProjectTitle extends React.Component {
  constructor(props) {
    super(props);
    this.titleRef = React.createRef();
    this.elRef = React.createRef();
    this.state = {
      title: ''
    };
  }
  transitionOut() {
    this.tlOut().play();
  }
  createTimelines() {
    this.splitText = new SplitText(this.titleRef.current, {
      type: 'words,chars'
    });
    this.words = this.splitText.words;
    this.chars = this.splitText.chars;
    // TweenMax.set(this.elRef.current, { overflow: 'hidden' });
    this.tlIn = new TimelineMax({
      paused: true
    });
    this.tlOut = new TimelineMax({
      paused: true,
      onComplete: () => {
        this.setState({ title: this.props.title }, this.onCompleteOutTimeline);
      }
    });

    this.tlIn.staggerFrom(
      this.chars,
      1,
      {
        x: -50,
        opacity: 0,
        ease: Expo.easeOut
      },
      -0.06
    );
    this.tlOut.staggerTo(
      this.chars,
      0.5,
      {
        x: 50,
        opacity: 0,
        ease: Expo.easeIn
      },
      -0.05
    );
  }

  onCompleteOutTimeline = () => {
    this.createTimelines();
    this.tlIn.play();
  };
  componentDidMount() {
    this.setState({ title: this.props.title }, () => {
      this.createTimelines();
      this.tlIn.play();
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      this.tlOut.play();
    }
    if (prevProps.isProjectMode !== this.props.isProjectMode) {
      if (this.props.isProjectMode) {
        this.tlOut.play();
      } else {
        this.tlIn.play();
      }
    }
  }

  render() {
    const { isTransparent } = this.props;
    const { title } = this.state;
    const titleClassNames = cx(styles.title, {
      [styles.isTransparent]: isTransparent
    });
    return (
      <div className={styles.wrapper} ref={this.elRef}>
        <h1 className={titleClassNames} ref={this.titleRef}>
          {title}
        </h1>
      </div>
    );
  }
}
ProjectTitle.propTypes = {
  title: PropTypes.string.isRequired,
  isTransparent: PropTypes.bool,
  isProjectMode: PropTypes.bool
};
export default ProjectTitle;
