class ScrollManager {
  constructor(callback) {
    this.callback = callback;
    this.init();
    this.offset = 0;
    this.timeout = null;
    this.canFireCallBack = true;
    this.maxOffset = 25;
  }

  init() {
    window.addEventListener('mousewheel', this.handleScroll);
  }

  handleScroll = event => {
    this.offset++;
    if (this.offset > this.maxOffset && this.canFireCallBack) {
      const dir = event.deltaY > 0;
      this.canFireCallBack = false;
      this.callback(dir);
    }
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.offset = 0;
      this.canFireCallBack = true;
    }, 250);
  };

  destroy() {
    window.removeEventListener('mousewheel', this.handleScroll);
  }
}

export default ScrollManager;
