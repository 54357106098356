import React, { Component } from 'react';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import ProjectDetail from './pages/ProjectDetail';
import About from './pages/About';
import Works from './pages/Works';
import Drafts from './pages/Drafts';
import Navigation from './component/Navigation';
import Cursor from './component/Cursor';
import { MOUSE_STATUS } from './component/Cursor/Cursor';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHover: false,
      isArrowHover: false,
      isLinkHover: false,
      isStalkerHover: false,
      isLittleHover: false,
      isLoadingHome: false,
      hideLinks: false,
      blockLoader: false,
      aboutDarkMode: false,
      toggleDarkMode: false,
      counter: 0
    };
  }

  changeState = (key, value) => {
    this.setState(prevstate => ({ ...prevstate, [key]: value }));
  };

  getMouseState() {
    if (this.state.isArrowHover) {
      return MOUSE_STATUS.CLICK;
    }

    if (this.state.isLinkHover || this.state.isStalkerHover) {
      return MOUSE_STATUS.NONE;
    }

    return this.state.isHover ? MOUSE_STATUS.HOLD : MOUSE_STATUS.NONE;
  }

  render() {
    const isCursorHover =
      this.state.isHover ||
      this.state.isStalkerHover ||
      this.state.isLinkHover ||
      this.state.isLittleHover ||
      this.getMouseState() === MOUSE_STATUS.CLICK;
    return (
      <HashRouter basename="/">
        <div className={`App ${this.state.aboutDarkMode ? 'dark-mode' : ''}`}>
          <Cursor
            isHover={isCursorHover}
            isStalkerHover={this.state.isStalkerHover}
            isLittleHover={this.state.isLittleHover}
            aboutDarkMode={this.state.toggleDarkMode}
            changeState={this.changeState}
            holdCounter={this.state.counter}
            mouseState={this.getMouseState()}
          />
          <Navigation
            appState={{ ...this.state, changeState: this.changeState }}
          />
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <Home
                  {...props}
                  appState={{ ...this.state, changeState: this.changeState }}
                />
              )}
            />
            <Route exact path="/projects" component={Projects} />
            <Route
              path="/projects/:slug"
              render={props => (
                <ProjectDetail
                  {...props}
                  appState={{ ...this.state, changeState: this.changeState }}
                />
              )}
            />
            <Route
              path="/works"
              render={props => (
                <Works
                  {...props}
                  appState={{ ...this.state, changeState: this.changeState }}
                />
              )}
            />
            <Route
              path="/drafts"
              render={props => (
                <Drafts
                  {...props}
                  appState={{ ...this.state, changeState: this.changeState }}
                />
              )}
            />
            <Route
              path="/about"
              render={props => (
                <About
                  {...props}
                  appState={{ ...this.state, changeState: this.changeState }}
                />
              )}
            />
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </div>
      </HashRouter>
    );
  }
}

export default App;
