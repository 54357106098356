class Vector {
  constructor(x = 0, y = 0, z = 0) {
    this.x = x;
    this.y = y;
    this.z = z;
  }

  set(x, y, z = 0) {
    this.x = x;
    this.y = y;
    this.z = z;

    return this;
  }

  add(x, y = 0, z = 0) {
    if (x instanceof Vector) {
      this.x += x.x;
      this.y += x.y;
      this.z += x.z;
    } else {
      this.x += x;
      this.y += y;
      this.z += z;
    }
    return this;
  }

  substract(x, y = 0, z = 0) {
    if (x instanceof Vector) {
      this.x -= x.x;
      this.y -= x.y;
      this.z -= x.z;
    } else {
      this.x -= x;
      this.y -= y;
      this.z -= z;
    }
    return this;
  }

  multiply(n) {
    if (typeof n !== 'number') {
      console.warn('Vector : multiply value is not a number');
    }
    this.x *= n;
    this.y *= n;
    this.z *= n;

    return this;
  }

  divide(n) {
    if (typeof n !== 'number') {
      console.warn('Vector : divide value is not a number');
    }
    if (n === 0) {
      console.warn('Vector : cannot divide a vector by zero');
      return this;
    }
    this.x /= n;
    this.y /= n;
    this.z /= n;

    return this;
  }

  normalize() {
    var len = this.magnitude();
    // here we multiply by the reciprocal instead of calling 'div()'
    // since div duplicates this zero check.
    if (len !== 0) this.multiply(1 / len);
    return this;
  }

  length() {
    return Math.sqrt(this.dot(this));
  }

  dot(v) {
    return this.x * v.x + this.y * v.y;
  }

  magnitude() {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }

  toString() {
    return `x: ${this.x}, y: ${this.y}, z ${this.z}`;
  }

  toArray() {
    return [this.x, this.y, this.z];
  }

  toObject() {
    const { x, y, z } = this;
    return { x, y, z };
  }

  limit(max) {
    const mSq = this.magnitude();
    if (mSq > max * max) {
      // console.log(this.divide(Math.sqrt(mSq)));
      this.divide(Math.sqrt(mSq)) //normalize it
        .multiply(max);
    }
    return this;
  }

  heading() {
    const h = Math.atan2(this.y, this.x);
    return h;
  }
}

export default Vector;
