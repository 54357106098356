import { useEffect } from 'react';
import { TimelineMax, TweenMax, Expo } from 'gsap';
import { SplitText } from '../vendor/gsap/SplitText';
export const useLetterizeLeft = (ref, duration) => {
  useEffect(() => {
    const el = ref.current;
    const splitText = new SplitText(el, { type: 'words,chars' });
    const { chars } = splitText;
    const tlIn = new TimelineMax({
      paused: true
    });
    tlIn.staggerFrom(
      chars,
      duration,
      {
        x: -50,
        opacity: 0,
        ease: Expo.easeOut
      },
      -0.04
    );

    tlIn.play();
  }, [ref]);
  useEffect(() => {
    const el = ref.current;
    TweenMax.to(el, { opacity: 0 });
  });
};
