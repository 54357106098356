import React from 'react';
import styles from './ProjectDetail.module.scss';
import data from '../../data/projects';
import ProjectDetailHero from '../../component/ProjectDetailHero';
import ProjectDetailImages from '../../component/ProjectDetailImages';
import BottomDetail from '../../component/BottomDetail';
import PropTypes from 'prop-types';
import TweenMax, { Expo } from 'gsap';

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      project: '',
      projectObject: null,
      shouldRender: true,
      ended: false
    };
    document.body.classList.remove('js-overflow-hidden');
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;
    this.setState({ project: params.slug });
    const project = data.projects.find(p => p.slug === params.slug);
    this.setState({ projectObject: project });
    this.props.appState.changeState('hideLinks', false);
  }

  onPressNext = () => {
    const { history } = this.props;
    const { index } = this.state.projectObject;
    let nextIndex = index + 1;
    if (nextIndex > data.projects.length - 1) {
      nextIndex = 0;
    }
    const project = data.projects.find(p => p.index === nextIndex);
    TweenMax.to(this.wrapperRef.current, 1.2, { autoAlpha: 0 });
    TweenMax.to(window, 1.3, {
      scrollTo: { y: 0 },
      ease: Expo.easeInOut,
      onComplete: () => {
        this.setState({ project: project.slug, projectObject: null });
        TweenMax.delayedCall(0.2, () => {
          this.setState({ project: project.slug, projectObject: project });
          TweenMax.to(this.wrapperRef.current, 0.7, {
            autoAlpha: 1,
            delay: 0.2
          });
          history.push(`/projects/${project.slug}`);
        });
      }
    });
  };

  componentWillMount() {
    this.setState({ projectObject: null });
  }

  render() {
    const { projectObject } = this.state;
    if (projectObject) {
      return (
        <div className={styles.wrapper} ref={this.wrapperRef}>
          <ProjectDetailHero project={projectObject} />
          <ProjectDetailImages
            images={projectObject.images}
            title={projectObject.title}
            color={projectObject.innerColor}
          />
          <BottomDetail
            project={projectObject}
            onPressNext={this.onPressNext}
            appState={this.props.appState}
          />
        </div>
      );
    }
    return <div />;
  }
}

ProjectDetail.propTypes = {
  history: PropTypes.any
};
export default ProjectDetail;
