import React from 'react';
import styles from './BottomDetail.module.scss';

const BottomDetail = ({ project, onPressNext, appState }) => {
  const onLinkHover = () => {
    appState.changeState('isLinkHover', true);
  };

  const onLinkLeave = () => {
    appState.changeState('isLinkHover', false);
  };

  const onLittleHover = () => {
    appState.changeState('isLittleHover', true);
  };

  const onLittleLeave = () => {
    appState.changeState('isLittleHover', false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.contentWrapper}>
        <div className={styles.left}>
          <div className={styles.leftContent}>
            <h5>Projects online publications</h5>
            <ul>
              {project.links.map((l, index) => {
                return (
                  <li key={`${index}-link`}>
                    <a
                      href={l.link}
                      onMouseEnter={onLittleHover}
                      onMouseLeave={onLittleLeave}
                    >
                      {l.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={styles.right}>
          <div
            className={styles.rightWrapper}
            onClick={onPressNext}
            onMouseEnter={onLinkHover}
            onMouseLeave={onLinkLeave}
          >
            <span>next project</span>

            <svg width="186px" height="31px" viewBox="0 0 186 31" version="1.1">
              <title>Fill 154</title>
              <desc>Created with Sketch.</desc>
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Detalle-Copy-2"
                  transform="translate(-1368.000000, -13666.000000)"
                >
                  <rect
                    id="Rectangle"
                    fill="transparent"
                    x="-2"
                    y="12513"
                    width="1920"
                    height="1702"
                  />
                  <g
                    id="arrow"
                    transform="translate(1461.000000, 13681.500000) scale(-1, 1) rotate(90.000000) translate(-1461.000000, -13681.500000) translate(1445.500000, 13588.500000)"
                    fill="#000000"
                  >
                    <polygon
                      id="Fill-154"
                      points="30.7034483 172.124915 15.7037203 185.703448 0.703448276 172.124915 2.13823798 170.826069 14.6835342 182.182723 14.6835342 0.296551724 16.7239064 0.296551724 16.7239064 182.182723 29.2686586 170.826069"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BottomDetail;
