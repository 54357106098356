import Vector from '../math/Vector';
import { TweenMax } from 'gsap';
class Point {
  constructor(angle, radius) {
    // console.log((angle * 180) / Math.PI);
    this.radius = radius;
    this.currentRadius = radius + this.getRandomWithNegative(-150, 150);
    // const sin = Math.sin(angle) / 100;
    // this.currentRadius = radius + this.getRandomWithNegative(-150 * sin, 150 * sin);
    // this.springForce = this.getRandomArbitrary(0.1, 0.2);
    this.springForce = Math.random() * 0.05;

    // this.currentRadius = radius;
    const x = this.currentRadius * Math.cos(angle);
    const y = this.currentRadius * Math.sin(angle);
    this.origin = new Vector(x, y);
    this.initLocation = new Vector(x, y);
    this.angle = angle;
    this.location = new Vector(0, 0);
    this.velocity = new Vector(0, 0);
    this.acceleration = new Vector(0, 0);
    this.restLength = this.radius;
    this.mass = 3;
    this.dampingForce = 0.98;
    this.maxSpeed = 0.5;
    this.maxForce = 0.5;
    this.frameCount = 0;
    this.isAtractingMouse = false;
    this.isProjectMode = false;
  }

  get x() {
    return this.location.x;
  }
  get y() {
    return this.location.y;
  }
  getPos() {
    return this.location;
  }

  setMouseAttraction(shouldAttract, vAttractor = null) {
    if (vAttractor) {
      this.vAttractor = new Vector(vAttractor.x, vAttractor.y);
    } else {
      this.vAttractor = null;
    }

    this.isAtractingMouse = shouldAttract;
  }
  setProjectMode(val, x, y) {
    this.isProjectMode = val;
    if (val) {
      TweenMax.to(this.location, 0.5, { x: this.location.x - x, y: this.location.y - y });
    }
  }
  setDestinationPosition(x, y) {
    // const newX = x * Math.cos(this.angle);
    // const newY = y * Math.sin(this.angle);
    // this.origin = new Vector(newX, newY);
    this.origin.x = x;
    this.origin.y = y;
  }

  update() {
    if (this.isProjectMode) {
      const x = 2000 * Math.cos(this.angle);
      const y = 2000 * Math.sin(this.angle);
      this.seek(new Vector(x, y));
    } else {
      this.frameCount += 0.1;
      this.addSpringForce();
      // this.addInverseSpringForce();
      if (this.isAtractingMouse && this.vAttractor) {
        this.seekMouse(this.vAttractor);
      }
      this.seek(this.origin);
    }

    this.velocity.add(this.acceleration);
    this.velocity.multiply(this.dampingForce);
    this.location.add(this.velocity);
    this.acceleration.multiply(0);
  }
  addSpringForce() {
    const xDiff = this.location.x - 0 + Math.cos(this.frameCount) * 19;
    const yDiff = this.location.y - 0 + Math.sin(this.frameCount) * 10;
    const spring = new Vector(xDiff, yDiff);
    const currentLength = spring.magnitude();
    spring.normalize();
    const stretch = currentLength - this.restLength;
    spring.multiply(-this.springForce * stretch);
    this.applyForce(spring);
  }
  addInverseSpringForce() {
    const xDiff = this.origin.x - this.location.x;
    const yDiff = this.origin.y - this.location.y;
    const spring = new Vector(xDiff, yDiff);
    const currentLength = spring.magnitude();
    spring.normalize();
    const stretch = currentLength - this.restLength;
    spring.multiply(-this.springForce * stretch);
    this.applyForce(spring);
  }

  applyForce(force) {
    force.divide(this.mass);
    this.acceleration.add(force);
  }

  getRandomWithNegative(m, n) {
    return Math.floor(Math.random() * (n - m + 1)) + m;
  }

  getRandomArbitrary(min, max) {
    return Math.random() * (max - min) + min;
  }

  seekCenter(target) {
    const desired = new Vector(target.x, target.y);
    desired.substract(this.location);
    desired.multiply(this.maxSpeed);
    desired.substract(this.velocity);
    desired.limit(5);
    this.applyForce(desired);
  }

  seek(target) {
    const desired = new Vector(target.x, target.y);
    desired.substract(this.location);
    desired.multiply(this.maxSpeed);
    desired.substract(this.velocity);
    desired.limit(this.maxForce);
    this.applyForce(desired);
  }
  seekMouse(target) {
    const desired = new Vector(target.x, target.y);
    desired.substract(this.location);
    desired.multiply(this.maxSpeed);
    desired.substract(this.velocity);
    desired.limit(0.51);
    // desired.multiply(-1);
    this.applyForce(desired);
  }
}

export default Point;
