import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import styles from './ProjectDetailImages.module.scss';
import { getAllChildNodes } from '../../lib/util/getAllChildNodes';

const ProjectDetailImages = ({ images, title, color }) => {
  const wrapperRef = useRef(null);
  const handleScroll = event => {
    if (wrapperRef.current) {
      const yOffset = window.pageYOffset;
      const nodes = getAllChildNodes(wrapperRef.current.childNodes);
      [...nodes].forEach((el, index) => {
        const top = el.offsetTop;
        const shiftDistance = (yOffset - top) * 0.15;
        el.childNodes[0].style.transform = `translateY(${shiftDistance}px) scale(${1 +
          shiftDistance * 0.0002})`;
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const colorStyles = { backgroundColor: color };
  return (
    <div className={styles.wrapper}>
      <div className={styles.colorWrapper} style={colorStyles} />
      <div className={styles.contentWrapper} ref={wrapperRef}>
        {images.map((img, index) => (
          <div className={styles.imageWrapper} key={`${index}-cheto`}>
            <img className={styles.image} src={img} alt={title} key={img} />
          </div>
        ))}
      </div>
    </div>
  );
};
ProjectDetailImages.propTypes = {
  images: propTypes.array.isRequired,
  title: propTypes.string.isRequired
};
export default ProjectDetailImages;
