import React, { useEffect, useRef } from 'react';
import propTypes from 'prop-types';
import styles from './ProjectDetailHero.module.scss';
import { useLetterizeLeft } from '../../hooks/useLetterIzeLeft';
import { TimelineMax, Expo, TweenMax } from 'gsap';
import { getAllChildNodes } from '../../lib/util/getAllChildNodes';

const ProjectDetailHero = ({ project }) => {
  const { number, title, subtitle, year, keywords } = project;
  const words = keywords.split(',');
  const numRef = useRef(null);
  const wrapperRef = useRef(null);
  const wrapperRightRef = useRef(null);
  useLetterizeLeft(numRef, 3);
  useEffect(() => {
    if (wrapperRef.current) {
      TweenMax.to(wrapperRef.current, 0.3, { opacity: 1 });
    }
    const nodes = getAllChildNodes(wrapperRightRef.current.childNodes);
    //   n => console.log(n);
    const tlIn = new TimelineMax({
      paused: true
    });
    tlIn
      .staggerFrom(
        nodes,
        2,
        {
          x: 50,
          opacity: 0,
          ease: Expo.easeOut
        },
        0.1
      )
      .play();
  }, [wrapperRightRef]);
  return (
    <div className={styles.wrapper} ref={wrapperRef}>
      <div className={styles.wrapperLeft}>
        <h1 className={styles.number} ref={numRef}>
          {number}
        </h1>
      </div>
      <div className={styles.wrapperRight} ref={wrapperRightRef}>
        <h2 className={styles.title}>{title}</h2>
        <h3 className={styles.subTitle}>{subtitle}</h3>
        <div className={styles.bottomWrapper}>
          <ul className={styles.keywords}>
            {words.map(w => (
              <li key={w}>{w}</li>
            ))}
          </ul>
          <p>{year}</p>
        </div>
      </div>
    </div>
  );
};

ProjectDetailHero.propTypes = {
  project: propTypes.object.isRequired
};

export default ProjectDetailHero;
