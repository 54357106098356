import React, { useEffect, useRef, useState } from 'react';
import './Drafts.scss';
import data from '../../data/drafts';
import { MotionWrapper, MotionChildren } from '../../component/motion';
import { Expo, TweenMax } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
const plugins = [ScrollToPlugin]; // eslint-disable-line

const getRandomFromArray = array =>
  array[Math.floor(Math.random() * array.length)];

const xPositions = ['left', '', 'right'];
const yPositions = ['top', '', 'bottom'];

const finalDrafts = data.drafts.map(draft => {
  return {
    ...draft,
    variation:
      Math.random() >= 1 / 2
        ? `sm ${getRandomFromArray(xPositions)} ${getRandomFromArray(
            yPositions
          )}`
        : '',
    padding: Math.floor(Math.random() * (60 - 10 + 1)) + 10
  };
});

const Drafts = props => {
  const list = useRef();

  useEffect(() => {
    props.appState.changeState('aboutDarkMode', false);
  }, []);

  let _timeout;
  const [scrollStatus, setScrollStatus] = useState('');

  const handleScroll = event => {
    if (_timeout) {
      //if there is already a timeout in process cancel it
      clearTimeout(_timeout);
    }

    _timeout = setTimeout(() => {
      _timeout = null;
      setScrollStatus('scroll stopped');
      TweenMax.to(window, 300 - document.documentElement.scrollTop / 30, {
        scrollTo: { y: 9000 }
      });
    }, 1000);
    if (scrollStatus !== 'scrolling') {
      setScrollStatus('scrolling');
    }
  };

  useEffect(() => {
    TweenMax.to(window, 1, { scrollTo: { y: 0 } });
    document.body.classList.remove('js-overflow-hidden');
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    setTimeout(() => {
      TweenMax.to(window, 0.1, { scrollTo: { y: 0 } });
    }, 10);

    setTimeout(() => {
      TweenMax.to(window, 400, {
        scrollTo: { y: 9000 },
        ease: Expo.easeInOut
      });
    }, 11);

    return () => {
      clearTimeout(_timeout);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const onImageHover = () => {
    props.appState.changeState('isLinkHover', true);
  };

  const onImageLeave = () => {
    props.appState.changeState('isLinkHover', false);
  };

  return (
    <div className="wrapper" ref={list}>
      <div className="title">Drafts</div>
      <MotionWrapper className="list">
        {finalDrafts.map(draft => {
          return (
            <MotionChildren
              animation="slide-up"
              key={draft.image}
              style={{ padding: `${draft.padding}px` }}
              className={`item ${draft.variation}`}
            >
              <div className="image">
                <a
                  href={`${draft.link}`}
                  target="_blank"
                  className="image-inner"
                  style={{ backgroundImage: `url(${draft.image})` }}
                  rel="noopener noreferrer"
                  onMouseEnter={onImageHover}
                  onMouseLeave={onImageLeave}
                >
                  <span />
                </a>
              </div>
            </MotionChildren>
          );
        })}
      </MotionWrapper>
    </div>
  );
};

export default Drafts;
