import React, { useEffect, useRef, useState } from 'react';
import styles from './About.module.scss';
import './About.scss';
import { useLetterizeLeft } from '../../hooks/useLetterIzeLeft';
import { TweenMax, Expo } from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
const plugins = [ScrollToPlugin]; // eslint-disable-line

const About = props => {
  const titleRef = useRef(null);
  const titleRef2 = useRef(null);
  const wrapperRef = useRef(null);
  const [rotation, setRotation] = useState(0);
  useLetterizeLeft(titleRef, 0.5);
  useLetterizeLeft(titleRef2, 0.5);
  useEffect(() => {
    TweenMax.to(window, 1, { scrollTo: { y: 0 } });
    document.body.classList.remove('js-overflow-hidden');
  }, []);
  useEffect(() => {
    if (wrapperRef.current) {
      TweenMax.fromTo(
        wrapperRef.current,
        0.5,
        { autoAlpha: 0, y: -0 },
        { autoAlpha: 1, y: 0, ease: Expo.easeInOut }
      );
    }
  }, [wrapperRef]);

  useEffect(() => {
    const { darkMode } = JSON.parse(
      `{"darkMode": ${localStorage.getItem('aboutDarkMode')}}`
    );
    props.appState.changeState('aboutDarkMode', darkMode);
  }, []);

  const onLinkHover = () => {
    props.appState.changeState('isStalkerHover', true);
  };

  const onClickDarkMode = () => {
    localStorage.setItem('aboutDarkMode', !props.appState.aboutDarkMode);
    setTimeout(() => {
      props.appState.changeState(
        'aboutDarkMode',
        !props.appState.aboutDarkMode
      );
    }, 2000);
    props.appState.changeState('toggleDarkMode', true);
  };

  const onLinkLeave = () => {
    props.appState.changeState('isStalkerHover', false);
  };

  const onLittleHover = () => {
    props.appState.changeState('isLittleHover', true);
  };

  const onLittleLeave = () => {
    props.appState.changeState('isLittleHover', false);
  };

  const onMailHover = () => {
    props.appState.changeState('isLinkHover', true);
  };

  const onMailLeave = () => {
    props.appState.changeState('isLinkHover', false);
  };

  const handleScroll = e => {
    setRotation(document.documentElement.scrollTop * 0.75);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${styles.wrapper}`} ref={wrapperRef}>
      <div className={`${styles.textAbout} textAbout`}>about</div>
      <div className={styles.heroWrapper}>
        <h1>
          <span
            ref={titleRef}
            onMouseEnter={onLinkHover}
            onMouseLeave={onLinkLeave}
          >
            Hello,
          </span>
          <br />{' '}
          <span
            ref={titleRef2}
            onMouseEnter={onLinkHover}
            onMouseLeave={onLinkLeave}
          >
            stalker
            {window.innerWidth > 768 && '.'}
          </span>
        </h1>
        <span className={`${styles.years} -hide-mobile`}>2014 - 2020</span>
        <div
          className={`${styles.darkMode} ${
            props.appState.aboutDarkMode ? styles.active : ''
          }`}
          onClick={onClickDarkMode}
        >
          <img
            className="dark-button dark-button-image"
            src="/img/dark-mode-button.svg"
            alt="Dark mode button"
            style={{ transform: `rotate(${rotation}deg)` }}
          />
          <img
            className="dark-button dark-button-image-white"
            src="/img/dark-mode-button-white.svg"
            alt="Dark mode off button"
            style={{ transform: `rotate(${rotation}deg)` }}
          />
        </div>
      </div>
      <div className={styles.gridTop}>
        <div>
          <img
            className="about-image"
            src="/img/about.svg"
            alt="About Iara grinspun"
          />
          <img
            className="about-image-dark"
            src="/img/about-dark.svg"
            alt="About Iara grinspun"
          />
        </div>
        <div className={styles.twoCols}>
          <span>english</span>
          <div>
            <p>
              Hi, glad you’re here! I’m a passionate gluten-free visual designer
              and art director based in Buenos Aires. I was lucky to find out I
              loved art and design at an early age, so I’ve dedicated my whole
              life to it. I graduated from University of Buenos Aires with a
              bachelor’s degree in Graphic Design and specialized in
              Illustration, Editorial Design and UX/UI.
            </p>
            <p>
              I’ve worked for renowned agencies and had the chance to develop
              the skills of digital design. I’m currently working in Despegar in
              the Design Ops team and also as a freelance artist and
              illustrator. I help companies all over the world materialize their
              essence. Feel free to contact me if you like my work!
            </p>
          </div>
        </div>
        <div className={styles.twoCols}>
          <span>spanish</span>
          <div>
            <p>
              ¡Hola! Gracias por visitar mi web. Soy diseñadora gráfica y
              directora de arte. Vivo en Buenos Aires y disfruto la vida sin
              gluten. Estudié y me recibí en la Universidad de Buenos Aires y me
              especialicé en ilustración, diseño editorial y UX/UI. Trabajé con
              empresas y clientes de todo el mundo, lo que me permitió conocer y
              adquirir experiencia en el universo del diseño y desarrollarme
              como diseñadora digital.
            </p>
            <p>
              Actualmente trabajo en Despegar en el equipo de Design Ops como
              UX/UI designer. Paralelamente me desempeño como visual designer e
              ilustradora freelance. Ayudo a compañías de todo el mundo a
              materializar su esencia. Si te interesa mi trabajo, ¡contactate!
            </p>
          </div>
        </div>
      </div>
      <div className={`${styles.gridBottom}`}>
        <div className={`${styles.gridBottomColumn} -hide-mobile`}>
          <h5>clients</h5>
          <ul>
            <li>Microsoft</li>
            <li>Nivea</li>
            <li>Ibm</li>
            <li>Whatsapp</li>
            <li>Adidas</li>
            <li>O.P.I</li>
            <li>Monoblock</li>
            <li>Allegra</li>
            <li>Unilever</li>
            <li>Giraffe games</li>
            <li>Viacom</li>
            <li>Apex America</li>
          </ul>
        </div>
        <div className={styles.gridBottomColumn}>
          <h5>work experience</h5>
          <div className={styles.gridBottomColumnFlex}>
            <ul>
              <li>
                <span>Despegar</span> Visual Designer
              </li>
              <li>
                <span>Possible</span> Art director
              </li>
              <li>
                <span>MediaMonks</span> Digital Graphic Designer
              </li>
              <li>
                <span>Monoblock</span> Graphic Designer & Editorial
              </li>
              <li>
                <span>DHNN</span> Graphic Designer
              </li>
            </ul>
            <ul>
              <li>2020</li>
              <li>2019</li>
              <li>2018</li>
              <li>2017</li>
              <li>2015</li>
            </ul>
          </div>
        </div>
        <div className={`${styles.gridBottomColumn} -hide-mobile`}>
          <h5>skills</h5>
          <ul>
            <li>Art direction</li>
            <li>Brand concept</li>
            <li>Identity system</li>
            <li>Brand guidelines</li>
            <li>Editorial design</li>
            <li>UX UI</li>
            <li>Design Ops</li>
            <li>Platforms </li>
            <li>UX Research</li>
            <li>Illustration</li>
            <li>Magazines</li>
            <li>Art cover</li>
          </ul>
        </div>
        <div className={styles.gridBottomColumn}>
          <h5>languages</h5>
          <ul>
            <li>English</li>
            <li>Spanish</li>
          </ul>
          <h5>publications</h5>
          <ul>
            <li>
              <a
                href="https://capsulesbook-portfolios.com/iara-grinspun-1"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={onLittleHover}
                onMouseLeave={onLittleLeave}
              >
                Capstules Book Interview
              </a>
            </li>
            <li>
              <a
                href="http://catedragabriele.com.ar/portfolio/nivel3/filtros/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={onLittleHover}
                onMouseLeave={onLittleLeave}
              >
                Filtro featured on University website
              </a>
            </li>
            <li>
              <a
                href="https://www.nngroup.com/articles/covid-changed-users/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={onLittleHover}
                onMouseLeave={onLittleLeave}
              >
                Nielsen Norman Group’s interview to Despegar team.
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.gridBottomColumn}>
          <h5>contact</h5>
          <ul>
            <li>
              <a
                href="https://www.behance.net/iaragrinspun"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={onLittleHover}
                onMouseLeave={onLittleLeave}
              >
                Behance
              </a>
            </li>
            <li>
              <a
                href="https://dribbble.com/iaragrinspunn"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={onLittleHover}
                onMouseLeave={onLittleLeave}
              >
                Dribbble
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/iara-grinspun-17647478"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={onLittleHover}
                onMouseLeave={onLittleLeave}
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.bottomContent}>
        <h5 onMouseEnter={onLinkHover} onMouseLeave={onLinkLeave}>
          TO ORDER GLUTEN - FREE FOOD
          <span>
            <svg width="390px" height="30px" viewBox="0 0 390 30" version="1.1">
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g id="About" transform="translate(-635.000000, -2794.000000)">
                  <g
                    id="arrow"
                    transform="translate(830.000000, 2809.000000) scale(-1, 1) rotate(90.000000) translate(-830.000000, -2809.000000) translate(815.000000, 2614.000000)"
                    fill="#000000"
                  >
                    <polygon
                      id="Fill-154"
                      className="arrow-contact"
                      points="30 376.416834 15.000272 390 0 376.416834 1.43478971 375.117545 13.980086 386.478074 13.980086 0 16.0204581 0 16.0204581 386.478074 28.5652103 375.117545"
                    />
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </h5>
        <p>
          Email me at:{' '}
          <a
            href="mailto:iaragrinspun@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={onMailHover}
            onMouseLeave={onMailLeave}
          >
            iaragrinspun@gmail.com
          </a>
        </p>
      </div>
      <div className={styles.disclaimer}>
        Coded in times of pandemic by <u>Fabricio Mouzo</u>,{' '}
        <u>Silvio Vitullo</u> and <u>Julian Schuster</u>.
      </div>
    </div>
  );
};

export default About;
