import React from 'react';
import styles from './ProjectsList.module.scss';
import PropTypes from 'prop-types';
import Blob from '../../lib/util/canvas/Blob';

class ProjectsList extends React.Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.context = null;
  }
  componentDidMount() {
    if (this.canvasRef.current) {
      this.canvas = this.canvasRef.current;
      this.context = this.canvasRef.current.getContext('2d');
      this.width = this.canvasRef.current.width;
      this.height = this.canvasRef.current.height;
      this.initBlobs();
    }
  }

  initBlobs() {
    this.blob = new Blob(this.context, this.width / 5, this.height / 5, 150, 5, 1);
    this.update();
  }
  update() {
    this.blob.update();
    this.draw();

    window.requestAnimationFrame(() => {
      this.update();
    });
  }

  draw() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.globalAlpha = 1;
    this.context.save();
    this.blob.draw();
    this.context.clip();
    // this.drawGradient();
    // this.drawBackgroundImages();
    this.context.restore();
  }

  render() {
    return (
      <div className={styles.wrapper}>
        <canvas ref={this.canvasRef} className={styles.canvas} />
      </div>
    );
  }
}

ProjectsList.propTypes = {
  projects: PropTypes.array.isRequired,
};
export default ProjectsList;
