import 'react-dat-gui/build/react-dat-gui.css';
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './BlobCarousel.module.scss';
import BlobCarouselCanvas from './BlobCarouselCanvas';
import ProjectTitle from '../ProjectTitle/ProjectTitle';
import { TweenMax, Expo } from 'gsap';
import ScrollManager from '../../lib/util/events/ScrollManager';
import BlobCarouselCounter from '../BlobCarouselCounter';
import BlobCarouselLoader from '../BlobCarouselLoader';

class BlobCarousel extends React.Component {
  constructor(props) {
    super(props);
    const { isHover, isArrowHover, counter } = props.appState;
    this.state = {
      currentProject: 0,
      isShowingProject: false
    };

    this.isHover = isHover;
    this.isArrowHover = isArrowHover;
    this.counter = counter;
    this.canvasRef = React.createRef();
    this.wrapperRef = React.createRef();
    this.projectTitleRef = React.createRef();
    this.projectTitleShadowRef = React.createRef();
    this.svgCircleRef = React.createRef();
    this.mouseInterval = null;
    this.mouseX = 0;
    this.mouseY = 0;
  }

  componentDidMount() {
    const { projects } = this.props;
    this.blobCanvas = new BlobCarouselCanvas(
      this.canvasRef.current,
      projects,
      this.handleProjectHoverIn,
      this.handleProjectHoverOut
    );
    document.body.classList.add('js-overflow-hidden');
    this.scrollManager = new ScrollManager(this.handleScroll);
    window.addEventListener('click', this.handleClick);
    // window.addEventListener('mousedown', this.handleMouseDown);
    // window.addEventListener('mouseup', this.handleMouseRelease);
    window.addEventListener('mousemove', this.handleMouseMove);

    // console.log(this.svgCircleRef.current);
    // if (this.svgCircleRef.current) {
    //   MorphSVGPlugin.convertToPath(this.svgCircleRef.current);
    //   TweenMax.set(this.svgCircleRef.current, { rotation: -90, transformOrigin: 'center center' });
    //   var tl = new TimelineMax({ repeat: -1, yoyo: true, repeatDelay: 0.1 });
    //   tl.to(this.svgCircleRef.current, 1.5, { drawSVG: '0%' });
    //   tl.to(this.svgCircleRef.current, 1, { opacity: 0 });
    //   tl.play();
    // }
  }

  handleClick = event => {
    if (
      this.state.isShowingProject ||
      !this.props.appState.isHover ||
      this.props.appState.isArrowHover
    )
      return;
    this.setState({ isShowingProject: true });
    this.blobCanvas.setShouldDrawImage(true);
    this.transitionOut();
    clearInterval(this.mouseInterval);
    this.props.appState.changeState('counter', 0);
    this.props.appState.changeState('isHover', false);
    this.props.appState.changeState('isArrowHover', false);
    this.blobCanvas.destroy();
    this.scrollManager.destroy();
    this.blobCanvas.enterProject();
  };

  // handleMouseDown = event => {
  //   if (this.state.isShowingProject || !this.state.isHover) return;
  //   // const { currentProject } = this.state;
  //   // const { projects } = this.props;
  //   // const backgroundColor = projects[currentProject].mainColor;
  //   // TweenMax.to(this.wrapperRef.current, 0.5, { backgroundColor });
  //   // this.blobCanvas.setShouldDrawImage(true);
  //   // this.setState({ isHover: true });
  //   this.mouseInterval = setInterval(this.onMouseInterval, 10);
  // };
  // handleMouseRelease = event => {
  //   console.log('HERE');
  //   if (this.state.isShowingProject) return;
  //   // const backgroundColor = '#FFFCEE';
  //   // TweenMax.to(this.wrapperRef.current, 0.5, { backgroundColor });
  //   // this.blobCanvas.setShouldDrawImage(false);
  //   clearInterval(this.mouseInterval);

  //   this.setState({ isHover: false, counter: 0 });
  // };
  // onMouseInterval = () => {
  //   console.log('INTER');
  //   this.setState({ counter: this.state.counter + 1 });
  //   if (this.state.counter > 150) {
  //     this.setState({ isShowingProject: true });
  //     this.blobCanvas.setShouldDrawImage(true);
  //     this.transitionOut();
  //     clearInterval(this.mouseInterval);
  //     this.setState({ counter: 0 });
  //     this.blobCanvas.enterProject();
  //   }
  // };

  handleScroll = direction => {
    const { currentProject, isShowingProject } = this.state;
    const { isHover } = this.props.appState;
    if (isShowingProject) return;
    const { projects } = this.props;
    let nextIndex = direction ? currentProject + 1 : currentProject - 1;
    if (nextIndex >= projects.length) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = projects.length - 1;
    }
    this.setState({ currentProject: nextIndex });
    this.blobCanvas.setCurrentProject(nextIndex);
    if (isHover) {
      const backgroundColor = projects[nextIndex].mainColor;
      TweenMax.to(this.wrapperRef.current, 0.5, {
        backgroundColor,
        delay: 0.9
      });
    }
  };

  handleProjectHoverIn = () => {
    const { currentProject } = this.state;
    const { isHover } = this.props.appState;
    if (isHover) return;
    const { projects } = this.props;
    const backgroundColor = projects[currentProject].mainColor;
    TweenMax.to(this.wrapperRef.current, 0.5, { backgroundColor });
    this.blobCanvas.setShouldDrawImage(true);
    this.props.appState.changeState('isHover', true);
    this.props.appState.changeState('hideLinks', true);
  };

  handleProjectHoverOut = () => {
    const { isHover } = this.props.appState;
    if (!isHover || this.state.isShowingProject) return;
    const backgroundColor = '#FFF4E3';
    TweenMax.to(this.wrapperRef.current, 0.5, { backgroundColor });
    this.blobCanvas.setShouldDrawImage(false);
    this.props.appState.changeState('isHover', false);
    this.props.appState.changeState('hideLinks', false);
  };

  transitionOut() {
    const { history, projects } = this.props;
    const { currentProject } = this.state;
    // const height = this.wrapperRef.current.clientHeight;
    TweenMax.to(this.wrapperRef.current, 1, {
      // top: -height,
      height: 0,
      transformOrigin: 'center',
      delay: 1,
      ease: Expo.easeOut,
      onComplete: () => {
        document.body.classList.remove('js-overflow-hidden');
        history.push(`projects/${projects[currentProject].slug}`);
      }
    });
  }

  handleNextClick = () => {
    const { currentProject, isShowingProject } = this.state;
    const { isHover } = this.props.appState;
    if (isShowingProject) return;
    const { projects } = this.props;
    let nextIndex = currentProject + 1;
    if (nextIndex >= projects.length) {
      nextIndex = 0;
    } else if (nextIndex < 0) {
      nextIndex = projects.length - 1;
    }
    this.setState({ currentProject: nextIndex });
    this.blobCanvas.setCurrentProject(nextIndex);
    if (isHover) {
      const backgroundColor = projects[nextIndex].mainColor;
      TweenMax.to(this.wrapperRef.current, 0.5, {
        backgroundColor,
        delay: 0.9
      });
    }
  };

  onArrowHover = () => {
    if (window.innerWidth > 768) {
      this.props.appState.changeState('isArrowHover', true);
    }
  };
  onArrowLeave = () => {
    this.props.appState.changeState('isArrowHover', false);
  };

  render() {
    const { currentProject, isShowingProject } = this.state;
    const { isHover } = this.props.appState;
    const { projects } = this.props;
    const titleWrapperFrontClasses = cx(styles.titleWrapperFront, {
      [styles.withOverlay]: isHover
    });
    const wrapperClasses = cx(styles.wrapper, {
      [styles.active]: isHover
    });
    const currentTitle = projects[currentProject].title;
    const type = projects[currentProject].projectType;
    return (
      <div className={wrapperClasses} ref={this.wrapperRef}>
        <canvas ref={this.canvasRef} className={styles.canvas} />
        {/* <p className={styles.scrollText}>Scroll >></p> */}
        {!this.props.appState.hideLinks && (
          <BlobCarouselCounter
            currentIndex={currentProject + 1}
            maxIndex={projects.length}
            isActive={isHover}
          />
        )}
        {!this.props.appState.hideLinks && (
          <BlobCarouselLoader type={type} isActive={isHover} />
        )}
        <div className={titleWrapperFrontClasses}>
          <ProjectTitle
            title={currentTitle}
            isTransparent={isHover}
            isProjectMode={isShowingProject}
          />
        </div>
        <div
          className={cx(
            styles.titleWrapperFront,
            isHover && styles.shadowTitle
          )}
        >
          <ProjectTitle
            title={currentTitle}
            isTransparent={isHover}
            isProjectMode={isShowingProject}
          />
        </div>
        <div
          className={styles.arrowLeft}
          onClick={this.handleNextClick}
          onMouseEnter={this.onArrowHover}
          onMouseLeave={this.onArrowLeave}
        >
          {!this.props.appState.hideLinks && (
            <svg
              width="52px"
              height="30px"
              viewBox="0 0 52 30"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="arrow"
                  transform="translate(26.000000, 15.000000) scale(-1, 1) rotate(90.000000) translate(-26.000000, -15.000000) translate(11.000000, -11.000000)"
                  fill={
                    isHover || this.props.appState.isArrowHover
                      ? '#ffffff'
                      : '#000000'
                  }
                >
                  <polygon
                    id="Fill-154"
                    points="30 38.1180182 15.000272 51.6965517 0 38.1180182 1.43478971 36.819172 13.980086 48.1758263 13.980086 0 16.0204581 0 16.0204581 48.1758263 28.5652103 36.819172"
                  />
                </g>
              </g>
            </svg>
          )}
        </div>
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
    // window.removeEventListener('mousedown', this.handleMouseDown);
    // window.removeEventListener('mouseup', this.handleMouseRelease);
    window.removeEventListener('mousemove', this.handleMouseMove);
    this.blobCanvas.destroy();
    this.scrollManager.destroy();
    this.blobCanvas = null;
  }
}

BlobCarousel.propTypes = {
  projects: PropTypes.array.isRequired,
  history: PropTypes.any
};
export default BlobCarousel;
