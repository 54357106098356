import React, { useEffect } from 'react';
import styles from './Works.module.scss';
import data from '../../data/projects';
import { MotionWrapper, MotionChildren } from '../../component/motion';
import { useSpring, animated } from 'react-spring';
import './index.scss';

const calc = (x, y, offsetWidth, offsetHeight) => [
  x - offsetWidth / 2,
  y - offsetHeight / 2
];
//const calc = (x, y) => [0, 0]
const trans1 = (x, y) => `translate(${-x / 3}px,${-y / 3}px)`;
const trans2 = (x, y) => `translate(${-(x - 400) / 10}px,${-(y - 200) / 10}px)`;

const Works = props => {
  const positions = data.projects.map(() => {
    const [props1, set] = useSpring(() => ({
      xy: [-200, -200],
      config: { mass: 10, tension: 550, friction: 140 }
    }));
    return { props1, set };
  });

  useEffect(() => {
    props.appState.changeState('aboutDarkMode', false);
  }, []);

  const onLinkHover = () => {
    props.appState.changeState('isLinkHover', true);
  };

  const onWheel = e => {
    const container = document.getElementById('container');
    const containerScrollPosition = document.getElementById('container')
      .scrollLeft;
    container.scrollTo({
      top: 0,
      left: containerScrollPosition + e.deltaY * 1.5,
      behaviour: 'smooth' //if you want smooth scrolling
    });
  };

  const onLinkLeave = (
    { clientX: x, clientY: y, currentTarget: { offsetWidth, offsetHeight } },
    i
  ) => {
    props.appState.changeState('isLinkHover', false);
    positions[i].set({
      xy: calc(0, 0, offsetWidth, offsetHeight)
    });
  };

  return (
    <div className={styles.wrapper}>
      <MotionWrapper className={styles.list} onWheel={onWheel} id="container">
        {data.projects.map((project, i) => (
          <MotionChildren
            key={project.index}
            className={styles.item}
            onMouseEnter={onLinkHover}
            onMouseLeave={e => onLinkLeave(e, i)}
            onMouseMove={({ clientX: x, clientY: y, currentTarget }) => {
              const rect = currentTarget.getBoundingClientRect();
              const { offsetWidth, offsetHeight } = currentTarget;
              positions[i].set({
                xy: calc(
                  x - rect.left - offsetWidth / 2,
                  y - rect.top - offsetHeight / 2,
                  offsetWidth,
                  offsetHeight
                )
              });
            }}
            onClick={() => {
              props.history.push(`/projects/${project.slug}`);
              props.appState.changeState('isLinkHover', false);
            }}
          >
            <svg className={styles.path}>
              <clipPath id={project.slug}>
                <path d={project.bloobPath} />
              </clipPath>
            </svg>
            <animated.div
              className={` ${styles.clipped} ${project.slug} `}
              style={{
                background: `${project.linearGradient}`,
                clipPath: `url(#${project.slug})`,
                WebkitClipPath: `url(#${project.slug})`,
                transform: positions[i].props1.xy.interpolate(trans2)
              }}
            >
              <div
                className={` ${styles.clippedImage}`}
                style={{ backgroundImage: `url(${project.mainImg})` }}
              />
            </animated.div>
            <animated.div
              className={styles.number}
              style={{ transform: positions[i].props1.xy.interpolate(trans1) }}
            >
              {project.number}
            </animated.div>
            <animated.div
              className={styles.title}
              style={{ transform: positions[i].props1.xy.interpolate(trans1) }}
            >
              {project.title}
            </animated.div>
          </MotionChildren>
        ))}
      </MotionWrapper>
    </div>
  );
};

export default Works;
