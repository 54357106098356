export default {
  drafts: [
    {
      image: '/img/drafts/1-100.jpg',
      link:
        'https://dribbble.com/shots/5404534-Miami-Fashion-Film-Festival-2019',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/2-100.jpg',
      link: 'https://dribbble.com/shots/11435099-Parking-App',
      size: 'sm',
      positionX: 'right',
      positionY: 'bottom'
    },
    {
      image: '/img/drafts/3-100.jpg',
      link: 'https://dribbble.com/shots/3293987-Illustrated-covers',
      size: 'sm',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/4-100.jpg',
      link: '',
      size: 'sm',
      positionX: 'right',
      positionY: ''
    },
    {
      image: '/img/drafts/5-100.jpg',
      link: '',
      size: 'sm',
      positionX: '',
      positionY: 'bottom'
    },
    {
      image: '/img/drafts/6-100.jpg',
      link: 'https://dribbble.com/shots/10753688-Flowers-Encyclopedia',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/7-100.jpg',
      link: 'https://dribbble.com/shots/10694801-Alice-Flowers',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/8-100.jpg',
      link: 'https://dribbble.com/shots/2753531-Allegra-Digital-Branding',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/9-100.jpg',
      link: 'https://dribbble.com/shots/2924466-Viacom-Channels-Identity',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/10-100.jpg',
      link:
        'https://dribbble.com/shots/12020553-UX-Branding-Design-Ilustraciones',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/11-100.jpg',
      link:
        'https://www.monoblock.tv/es/de-decoracion/1748-conquista-tu-casa-9789874655431.html',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/12-100.jpg',
      link: 'https://dribbble.com/shots/10769475-Design-Museum-Web-Concept',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/13-100.jpg',
      link: 'https://dribbble.com/shots/3885454-Water-Arts-Festival-2018',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/14-100.jpg',
      link: 'https://dribbble.com/shots/2924621-Viacom-Channels-Identity',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/15-100.jpg',
      link: 'https://dribbble.com/shots/2755665-Allegra-Digital-Branding',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/16-100.jpg',
      link:
        'https://dribbble.com/shots/11504829-UX-Branding-Design-Ilustraciones',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/17-100.jpg',
      link: 'https://dribbble.com/shots/3290972-Illustrated-covers',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/18-100.jpg',
      link: 'https://dribbble.com/shots/3167570-Flowers-Encyclopedia',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/19-100.jpg',
      link:
        'https://www.behance.net/gallery/47012273/Cablevision-Fibertel-Website?tracking_source=search_projects_recommended%7Cdhnn',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/20-100.jpg',
      link: 'https://dribbble.com/shots/10694786-Alice-Flowers',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/21-100.jpg',
      link:
        'https://www.monoblock.tv/es/de-decoracion/1748-conquista-tu-casa-9789874655431.html',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/22-100.jpg',
      link: 'https://dribbble.com/shots/2635967-Portfolio-Mag',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/23-100.jpg',
      link: '',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/24-100.jpg',
      link: 'https://dribbble.com/shots/2922922-Viacom-Channels-Identity',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/25-100.jpg',
      link:
        'https://www.behance.net/gallery/97412775/UX-Branding-Design-Ilustraciones?tracking_source=search_projects_recommended%7Cdespegar',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/26-100.jpg',
      link: 'https://dribbble.com/shots/5375810-Book-Design',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/27-100.jpg',
      link:
        'https://www.behance.net/gallery/37915945/AllegraDigital-Branding?tracking_source=search_projects_recommended%7Cdhnn',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/28-100.jpg',
      link: 'https://dribbble.com/shots/3167681-Flowers-Encyclopedia',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/29-100.jpg',
      link: 'https://dribbble.com/shots/5417376-The-Design-Museum-Web',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/30-100.jpg',
      link: 'https://dribbble.com/shots/3293984-Illustrated-covers',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/31-100.jpg',
      link: 'https://dribbble.com/shots/3139539-Giraffe-Games-Work-In-Progress',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/32-100.jpg',
      link: 'https://dribbble.com/shots/3084261-Magazine-Concept',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/33-100.jpg',
      link: 'https://dribbble.com/shots/3400427-Optician-Game-Collection',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/34-100.jpg',
      link: 'https://dribbble.com/shots/2807575-Illustrated-Book',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/35-100.jpg',
      link: '',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/36-100.jpg',
      link: '',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/37-100.jpg',
      link: 'https://dribbble.com/shots/3030038-Magazine-Concept',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/38-100.jpg',
      link: 'https://dribbble.com/shots/2928936-Apex-Presentation',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/39-100.jpg',
      link:
        'https://dribbble.com/shots/5375944-Miami-Fashion-Film-Festival-2019',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/40-100.jpg',
      link:
        'https://dribbble.com/shots/11649498-UX-Branding-Design-Ilustraciones',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/41-100.jpg',
      link: '',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/42-100.jpg',
      link: 'https://dribbble.com/shots/3156607-Illustrated-Book',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/43-100.jpg',
      link: 'https://dribbble.com/shots/3062423-Magazine-Concept',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/44-100.jpg',
      link:
        'https://www.behance.net/gallery/37915945/AllegraDigital-Branding?tracking_source=search_projects_recommended%7Cdhnn',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/45-100.jpg',
      link: 'https://dribbble.com/shots/3646899-Magazzzzzzzzzine',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/46-100.jpg',
      link: 'https://dribbble.com/shots/6086796-Labe-L-01',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/47-100.jpg',
      link: 'https://dribbble.com/shots/2931136-Apex-Presentation',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/48-100.jpg',
      link: 'https://dribbble.com/shots/2812357-Illustrated-Book',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/49-100.jpg',
      link:
        'https://www.monoblock.tv/es/de-decoracion/1748-conquista-tu-casa-9789874655431.html',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/50-100.jpg',
      link: '',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/51-100.jpg',
      link: '',
      size: '',
      positionX: '',
      positionY: ''
    },
    {
      image: '/img/drafts/52-100.jpg',
      link: '',
      size: '',
      positionX: '',
      positionY: ''
    }
  ]
};
