import React, { useEffect } from 'react';
import styles from './Home.module.scss';
import BlobCarousel from '../../component/BlobCarousel';
import data from '../../data/projects';
import CountUp from 'react-countup';

const Home = props => {
  useEffect(() => {
    if (!props.appState.blockLoader) {
      props.appState.changeState('isLoadingHome', true);
    }
    props.appState.changeState('aboutDarkMode', false);
  }, []);

  return (
    <div className={styles.wrapper}>
      {props.appState.isLoadingHome ? (
        <span
          className={`${styles.overlay} ${
            props.appState.isLoadingHome ? styles.loading : ''
          }`}
        >
          <div className={styles.title}>Iara Grinspun</div>
          <CountUp
            className={styles.loader}
            start={0}
            end={100}
            duration={2.5}
            onEnd={() => {
              props.appState.changeState('isLoadingHome', false);
              props.appState.changeState('blockLoader', true);
            }}
            suffix="’"
          />
          <div className={styles.copyright}>
            <p>portfolio 2020</p>
            <p>buenos aires - argentina</p>
          </div>
        </span>
      ) : (
        <BlobCarousel projects={data.projects} {...props} />
      )}
    </div>
  );
};

export default Home;
