import React from 'react';
import styles from './BlobCarouselCounter.module.scss';
import classnames from 'classnames';

const BlobCarouselCounter = props => {
  const { currentIndex, maxIndex, isActive } = props;
  return (
    <div className={classnames(styles.wrapper, isActive && styles.active)}>
      <div className={styles.innerWrapper}>
        <span className={styles.currentIndex}>{currentIndex}</span>
        <span className={styles.bar}>-</span>
        <span className={styles.maxIndex}>{maxIndex}</span>
      </div>
    </div>
  );
};

export default BlobCarouselCounter;
