import React, { useEffect, useRef } from 'react';
import { TweenMax, Expo, TimelineMax } from 'gsap';
import classnames from 'classnames';
import styles from './BlobCarouselLoader.module.scss';
import { SplitText } from '../../vendor/gsap/SplitText';

const BlobCarouselLoader = ({ type, isActive }) => {
  const lineRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current);

    const splitText = new SplitText(textRef.current, { type: 'words,chars' });
    const { chars } = splitText;
    const tlIn = new TimelineMax({
      paused: true
    });
    const tlOut = new TimelineMax({
      paused: true
    });
    tlIn.staggerFrom(
      chars,
      1,
      {
        x: -10,
        opacity: 0,
        ease: Expo.easeOut
      },
      0.05
    );
    tlOut.staggerTo(
      chars,
      0.9,
      {
        // x: 0,
        opacity: 1,
        ease: Expo.easeIn,
        onComplete: () => {
          tlIn.play();
        }
      },
      0.05
    );

    tlIn.play();
    TweenMax.fromTo(
      lineRef.current,
      1,
      { width: 0 },
      { width: 100, ease: Expo.easeOut }
    );
  }, [type]);
  return (
    <div className={classnames(styles.wrapper, isActive && styles.active)}>
      <div className={styles.innerWrapper}>
        <div className={styles.line} ref={lineRef} />
        <span className={styles.text} ref={textRef}>
          {type}
        </span>
      </div>
    </div>
  );
};

export default BlobCarouselLoader;
