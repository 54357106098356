import React from 'react';
import styles from './Projects.module.scss';
import ProjectsList from '../../component/ProjectsList';
import data from '../../data/projects';

const Projects = () => (
  <div className={styles.wrapper}>
    <ProjectsList projects={data.projects} />
  </div>
);

export default Projects;
