import React, { useRef, useEffect } from 'react';
import styles from './Cursor.module.scss';
import TweenMax, { Expo } from 'gsap';

export const MOUSE_STATUS = {
  NONE: '',
  HOLD: 'View Project',
  CLICK: 'Next Project',
  CLOSE: 'Close'
};

const Cursor = ({
  isHover,
  mouseState,
  holdCounter,
  isStalkerHover,
  isLittleHover,
  aboutDarkMode,
  changeState
}) => {
  const mouseRef = useRef(null);
  const handleMouseMove = event => {
    const mouseX = event.clientX;
    const mouseY = event.clientY;
    if (mouseRef.current) {
      TweenMax.to(mouseRef.current, 1, {
        left: mouseX - 25,
        top: mouseY - 25,
        ease: Expo.easeOut
      });
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  });

  const getMultiply = () => {
    if (isStalkerHover) return 2.1;
    else if (isLittleHover) return 0.5;
    else return 1;
  };

  useEffect(() => {
    if (mouseRef.current && !aboutDarkMode) {
      const multiply = getMultiply();
      TweenMax.to(mouseRef.current, 0.5, { scale: isHover ? multiply * 4 : 1 });
    }
  }, [isHover]);

  useEffect(() => {
    if (mouseRef.current) {
      TweenMax.to(mouseRef.current, 1, { scale: aboutDarkMode ? 50 * 4 : 1 });
      setTimeout(() => {
        if (aboutDarkMode) {
          TweenMax.to(mouseRef.current, 0, { scale: 1 });

          changeState('toggleDarkMode', false);
        }
      }, 2000);
    }
  }, [aboutDarkMode]);

  //   const ballStyles = { transform: `scale(${holdCounter / 20})` };
  const ballStyles = {
    opacity: holdCounter / 150,
    transform: `scale(${1 - holdCounter / 150})`
  };
  const bigBallStyles = { transform: `scale(${1 + holdCounter / 10})` };
  //   const ballStyles = { opacity: holdCounter / 100 };
  //   console.log(holdCounter);
  //   const ballStyles = {};

  return (
    window.innerWidth > 768 && (
      <div className={styles.mouseCursor}>
        <div className={styles.cursorBall} ref={mouseRef}>
          <span>{mouseState}</span>
          <svg height="30" width="30" style={bigBallStyles}>
            <circle cx="15" cy="15" r="12" strokeWidth="0" />
          </svg>
          <div className={styles.ballSmall} style={ballStyles}>
            <svg height="30" width="30">
              <circle cx="15" cy="15" r="12" strokeWidth="0" />
            </svg>
          </div>
        </div>
      </div>
    )
  );
};

export default Cursor;
