export default {
  projects: [
    {
      index: 0,
      number: '01',
      slug: 'book',
      title: 'Book',
      subtitle: 'Fashion Portfolio for Melanie Tarica',
      mainColor: '#FFDBC1',
      gradient1: '#FFB091',
      gradient2: '#FFD6B4',
      innerColor: '#FBE7E8',
      linearGradient:
        'linear-gradient(120deg, rgba(255,158,120,1) 0%, rgba(255,215,190,1) 100%)',
      bloobPath:
        'M124.23508,12.6170013 C98.662534,19.7719467 73.4151936,29.5577661 51.6848956,44.8632622 L51.6848956,44.8632622 C29.9511564,60.1704778 11.8703922,81.4341296 4.28053199,106.086966 L4.28053199,106.086966 C-6.13982467,139.928947 4.62638597,176.84028 26.9313865,203.236715 L26.9313865,203.236715 C49.2381078,229.631431 81.8120464,246.431195 115.968998,256.211856 L115.968998,256.211856 C141.851265,263.626449 169.595292,267.326868 196.66998,262.225036 L196.66998,262.225036 C223.742948,257.124924 250.110442,242.342163 264.806655,219.396816 L264.806655,219.396816 C271.725456,208.594757 275.941089,196.319064 283.754635,186.07929 L283.754635,186.07929 C294.039059,172.60165 309.518176,163.916327 323.21124,153.637004 L323.21124,153.637004 C332.034818,147.015111 340.576207,138.921305 345.813916,129.620391 L345.813916,129.620391 L345.168666,131.683822 C346.054809,129.874881 346.85664,128.033269 347.575879,126.165865 L347.575879,126.165865 C349.108993,122.81451 350.194733,119.318714 350.70233,115.692235 L350.70233,115.692235 C353.687686,101.831141 352.443644,87.2891138 346.897936,74.5663449 L346.897936,74.5663449 C341.864986,63.0283291 333.581698,53.1616921 324.298702,44.5399914 L324.298702,44.5399914 C306.531099,28.0377056 284.723371,15.600378 260.967848,8.41963967 L260.967848,8.41963967 C242.887084,2.95498777 223.975238,0.585481214 204.82594,0.585481214 L204.82594,0.585481214 C177.899229,0.585481214 150.497614,5.26774951 124.23508,12.6170013',
      mainImg: '/img/01_Book/01-Cover.jpg',
      projectType: 'Editorial',
      keywords: 'Branding, Art Direction, Editorial Design',
      year: '2016',
      images: [
        '/img/01_Book/JPGS/1.jpg',
        '/img/01_Book/JPGS/2.jpg',
        '/img/01_Book/JPGS/3.jpg',
        '/img/01_Book/JPGS/4.jpg',
        '/img/01_Book/JPGS/5.jpg',
        '/img/01_Book/JPGS/6.jpg',
        '/img/01_Book/JPGS/7.jpg'
      ],
      links: [
        {
          title: 'Behance',
          link:
            'https://www.behance.net/gallery/37453585/Portfolio-Edition-Fashion-Student'
        },
        {
          title: 'Editorial Showcase',
          link: ' https://1stwebdesigner.com/getting-your-design-work-noticed/'
        },
        {
          title: 'Grid Based Editorial Design',
          link:
            'https://spyrestudios.com/10-grid-based-editorial-design-examples/'
        }
      ]
    },
    {
      index: 1,
      number: '02',
      slug: 'filtro',
      title: 'Filtro',
      subtitle: 'Water Arts Festival',
      mainColor: '#FFCFD7',
      gradient1: '#FE92AA',
      gradient2: '#FECED5',
      innerColor: '#FFEFF3',
      linearGradient:
        'linear-gradient(0deg, rgba(255,190,204,1) 0%, rgba(254,149,177,1) 100%)',
      bloobPath:
        'M52.5478655,13.5708059 C32.8316096,35.4506685 42.4684185,73.591002 32.0118358,102.452236 L32.0118358,102.452236 C26.0034691,119.031155 13.5778412,131.703773 6.69015295,147.84503 L6.69015295,147.84503 C-3.23248501,171.090139 -0.544897133,198.546933 4.66354496,223.661906 L4.66354496,223.661906 C9.71914786,248.061594 17.7104542,273.197797 34.8443232,289.187178 L34.8443232,289.187178 C50.0845741,303.406313 70.5451766,308.403505 90.3051008,309.652803 L90.3051008,309.652803 C147.806381,313.289648 223.852811,288.49639 237.737359,228.593775 L237.737359,228.593775 C242.419796,208.391075 236.381655,173.430327 239.660751,146.34424 L239.660751,146.34424 C242.908087,119.519443 249.02761,89.9723202 236.210952,67.0652571 L236.210952,67.0652571 C229.702384,55.4345375 219.166404,47.4831231 208.560952,40.6650587 L208.560952,40.6650587 C176.875601,20.2925192 141.676934,6.88175405 105.515578,1.39954039 L105.515578,1.39954039 C100.021307,0.566675048 94.2947992,0 88.6119601,0 L88.6119601,0 C75.140277,0 61.9087698,3.18448514 52.5478655,13.5708059',
      mainImg: '/img/02_Filtro/02-Cover.jpg',
      projectType: 'Branding',
      keywords: 'Branding, Art Direction, Illustration, University Project',
      year: '2017',
      images: [
        '/img/02_Filtro/JPGS/1.jpg',
        '/img/02_Filtro/JPGS/2.jpg',
        '/img/02_Filtro/JPGS/3.jpg',
        '/img/02_Filtro/JPGS/4.jpg',
        '/img/02_Filtro/JPGS/5.jpg',
        '/img/02_Filtro/JPGS/6.jpg',
        '/img/02_Filtro/JPGS/7.jpg',
        '/img/02_Filtro/JPGS/8.jpg',
        '/img/02_Filtro/JPGS/9.jpg'
      ],
      links: [
        {
          title: 'Behance',
          link:
            'https://www.behance.net/gallery/61555935/Filtro-Festival-de-artes-acuaticas'
        },
        {
          title: 'Capsules Book Interview',
          link: 'https://capsulesbook-portfolios.com/iara-grinspun-1'
        }
      ]
    },
    {
      index: 2,
      number: '03',
      slug: 'museum',
      title: 'Museum',
      subtitle: 'The Design Museum',
      mainColor: '#ABB9DD',
      gradient1: '#263E8E',
      gradient2: '#9BD3FF',
      innerColor: '#F5F5F5',
      linearGradient:
        'linear-gradient(45deg, rgba(36,59,147,1) 0%, rgba(139,212,255,1) 100%)',
      bloobPath:
        'M22.4171206,58.5753323 C-32.5243457,141.484698 25.7948423,198.777729 66.4537722,226.003055 L66.4537722,226.003055 C86.0940136,239.180715 103.794608,255.031291 119.038199,273.084842 L119.038199,273.084842 C146.500164,305.549436 199.333623,345.88953 262.258157,282.68114 L262.258157,282.68114 C307.862661,236.86768 307.187467,177.193481 294.725323,128.376919 L294.725323,128.376919 C276.016323,55.0900573 211.367853,2.18725032 135.484863,0.0646391869 L135.484863,0.0646391869 C133.957347,0.0209640606 132.42457,0 130.891792,0 L130.891792,0 C92.1672483,0 51.9818308,13.9620644 22.4171206,58.5753323',
      mainImg: '/img/03_Museum/03-Cover.jpg',
      projectType: 'uxui',
      keywords: 'UX/UI,Content,Art Direction,University Project',
      year: '2017',
      images: [
        '/img/03_Museum/JPGS/1.jpg',
        '/img/03_Museum/JPGS/2.jpg',
        '/img/03_Museum/JPGS/3.jpg',
        '/img/03_Museum/JPGS/5.jpg',
        '/img/03_Museum/JPGS/6.jpg',
        '/img/03_Museum/JPGS/8.jpg',
        '/img/03_Museum/JPGS/9.jpg'
      ],
      links: [
        {
          title: 'Behance',
          link:
            'https://www.behance.net/gallery/72132125/Museum-Website-re-design'
        }
      ]
    },
    {
      index: 3,
      number: '04',
      slug: 'labell',
      title: 'Labe-L',
      subtitle: 'Concept Project',
      mainColor: '#FFE3A6',
      gradient1: '#FFE3A6',
      gradient2: '#FFB82E',
      innerColor: '#FFF4CE',
      linearGradient:
        'linear-gradient(45deg, rgba(255,194,56,1) 0%, rgba(255,225,154,1) 100%)',
      bloobPath:
        'M76.488641,73.1810799 C60.9345649,85.3604915 46.4440154,100.044902 41.2200029,118.222487 L41.2200029,118.222487 C35.9229665,136.642913 40.8118184,156.520385 35.8574323,175.023003 L35.8574323,175.023003 C28.7591414,201.528167 2.3675798,222.104274 0.145033963,249.328622 L0.145033963,249.328622 C-2.47071712,281.394845 30.7289124,307.528276 64.6924836,314.974156 L64.6924836,314.974156 C89.953112,320.512799 117.582334,318.721379 140.412579,307.326531 L140.412579,307.326531 C167.892009,293.613485 185.58999,268.062873 208.652414,248.635591 L208.652414,248.635591 C246.572379,216.694524 301.553706,198.767252 322.871048,155.702197 L322.871048,155.702197 C338.971866,123.168972 327.939651,0.0186821933 233.677119,0 L233.677119,0 C195.871371,-0.0074699174 144.696644,19.7896779 76.488641,73.1810799',
      mainImg: '/img/04_Label/04-Cover.jpg',
      projectType: 'ILLUSTRATION',
      keywords: 'Art Direction, Illustration',
      year: '2020',
      images: [
        '/img/04_Label/JPGS/1.jpg',
        '/img/04_Label/JPGS/2.jpg',
        '/img/04_Label/JPGS/3.jpg',
        '/img/04_Label/JPGS/4.jpg',
        '/img/04_Label/JPGS/5.jpg',
        '/img/04_Label/JPGS/6.jpg',
        '/img/04_Label/JPGS/7.jpg',
        '/img/04_Label/JPGS/8.jpg',
        '/img/04_Label/JPGS/9.jpg',
        '/img/04_Label/JPGS/10.jpg',
        '/img/04_Label/JPGS/11.jpg',
        '/img/04_Label/JPGS/12.jpg',
        '/img/04_Label/JPGS/13.jpg',
        '/img/04_Label/JPGS/14.jpg',
        '/img/04_Label/JPGS/15.jpg',
        '/img/04_Label/JPGS/16.jpg'
      ],
      links: [
        {
          title: 'Behance',
          link:
            'https://www.behance.net/gallery/93630349/LABE-L-Poster-Collection'
        }
      ]
    },
    {
      index: 4,
      number: '05',
      slug: 'parkit',
      title: 'Park-it',
      subtitle: 'Parking App',
      mainColor: '#B39DF6',
      gradient1: '#B39DF6',
      gradient2: '#EA8FE5',
      innerColor: '#D2C9F4',
      linearGradient:
        'linear-gradient(320deg, rgba(248,134,233,1) 0%, rgba(185,153,252,1) 100%)',
      bloobPath:
        'M71.851328,5.76917172 C51.5444496,12.0283889 30.6270827,21.9215797 17.3164011,43.1287749 L17.3164011,43.1287749 C5.48106635,61.9891614 1.32567545,87.3124204 0.28377528,111.770145 L0.28377528,111.770145 C-2.74221212,182.939703 18.4010354,252.583439 65.2051446,288.05764 L65.2051446,288.05764 C74.8305113,295.354226 91.4154457,295.277855 108.002415,295.201484 L108.002415,295.201484 C117.890292,295.156935 127.782238,295.112385 136.200873,296.628662 L136.200873,296.628662 C158.528468,300.649263 183.116905,308.222693 202.184492,292.358266 L202.184492,292.358266 C211.864803,284.302746 218.482497,271.260854 224.155969,258.137818 L224.155969,258.137818 C241.111266,218.918269 252.273029,175.353545 256.833377,130.595528 L256.833377,130.595528 C259.171548,107.676356 258.996541,81.5098365 246.70334,65.0392172 L246.70334,65.0392172 C228.496541,40.635589 196.751146,52.5621503 172.732498,39.6204948 L172.732498,39.6204948 C158.933426,32.1838956 148.386221,16.8063323 134.953442,8.27985924 L134.953442,8.27985924 C125.488837,2.27202902 115.185828,0 104.681358,0 L104.681358,0 C93.7129169,0 82.5267348,2.47727534 71.851328,5.76917172',
      mainImg: '/img/05_Parkit/05-Cover.jpg',
      projectType: 'UX/UI',
      keywords: 'Branding, Art Direction, Editorial Design',
      year: '2018',
      images: [
        '/img/05_Parkit/JPGS/1.jpg',
        '/img/05_Parkit/JPGS/2.jpg',
        '/img/05_Parkit/JPGS/3.jpg',
        '/img/05_Parkit/JPGS/4.jpg',
        '/img/05_Parkit/JPGS/5.jpg',
        '/img/05_Parkit/JPGS/6.jpg',
        '/img/05_Parkit/JPGS/7.jpg',
        '/img/05_Parkit/JPGS/8.jpg',
        '/img/05_Parkit/JPGS/9.jpg',
        '/img/05_Parkit/JPGS/10.jpg'
      ],
      links: [
        {
          title: 'Behance',
          link:
            'https://www.behance.net/gallery/96781107/Park-it-App-Case-study'
        }
      ]
    },
    {
      index: 5,
      number: '06',
      slug: 'expo',
      title: 'Expo',
      subtitle: 'Photographic Exposition',
      mainColor: '#C9DEF1',
      gradient1: '#CADFF2',
      gradient2: '#8FB6C9',
      innerColor: '#DFEDF2',
      linearGradient:
        'linear-gradient(320deg, rgba(132,183,202,1) 0%, rgba(198,223,244,1) 100%)',
      bloobPath:
        'M134.167725,13.0341468 C106.48835,20.7853376 79.160973,31.386642 55.6403948,47.9675961 L55.6403948,47.9675961 C32.1160918,64.550413 12.5457192,87.5860358 4.33055807,114.293275 L4.33055807,114.293275 C-6.94829389,150.955421 4.7049057,190.942699 28.8475342,219.538837 L28.8475342,219.538837 C52.9920251,248.133112 88.249612,266.332856 125.220631,276.928572 L125.220631,276.928572 C153.235243,284.961049 183.265,288.969836 212.570273,283.442851 L212.570273,283.442851 C241.873684,277.917729 270.413501,261.903072 286.320481,237.045612 L286.320481,237.045612 C293.809296,225.343382 298.37224,212.044715 306.829517,200.951627 L306.829517,200.951627 C317.961237,186.350849 334.715621,176.941749 349.536808,165.805816 L349.536808,165.805816 C359.087328,158.632099 368.332411,149.863809 374.001636,139.787819 L374.001636,139.787819 L373.303226,142.023203 C374.262375,140.063517 375.130266,138.068437 375.90876,136.045416 L375.90876,136.045416 C377.568182,132.414781 378.743372,128.627669 379.292788,124.698984 L379.292788,124.698984 C382.524097,109.682798 381.177563,93.9289353 375.174964,80.1459357 L375.174964,80.1459357 C369.727368,67.6464186 360.761649,56.9575617 350.713861,47.617386 L350.713861,47.617386 C331.48245,29.7399098 307.878063,16.2661382 282.165409,8.48700499 L282.165409,8.48700499 C262.595036,2.56696543 242.125112,2.84217094e-14 221.398173,2.84217094e-14 L221.398173,2.84217094e-14 C192.253068,2.84217094e-14 162.593934,5.07245732 134.167725,13.0341468',
      mainImg: '/img/06_Expo/06-Cover.jpg',
      projectType: 'Branding',
      keywords: 'Art Direction, University Project',
      year: '2017',
      images: [
        '/img/06_Expo/JPGS/1.jpg',
        '/img/06_Expo/JPGS/2.jpg',
        '/img/06_Expo/JPGS/3.jpg',
        '/img/06_Expo/JPGS/4.jpg',
        '/img/06_Expo/JPGS/5.jpg'
      ],
      links: [
        {
          title: 'Behance',
          link:
            'https://www.behance.net/gallery/61825043/Filtro-Exposicion-Temporal'
        }
      ]
    },
    {
      index: 6,
      number: '07',
      slug: 'library',
      title: 'Library',
      subtitle: 'Illustrated Library for Despegar',
      mainColor: '#EDBBB9',
      gradient1: '#EDBBB9',
      gradient2: '#F06251',
      innerColor: '#F1E8D5',
      linearGradient:
        'linear-gradient(45deg, rgba(245,199,191,1) 0%, rgba(247,143,123,1) 100%)',
      bloobPath:
        'M61.6269425,0.0429592141 C47.0388766,9.96840546 49.3341755,32.251163 50.3657495,50.3519345 L50.3657495,50.3519345 C52.3933259,85.8997502 42.3659027,122.065805 22.4514704,151.031522 L22.4514704,151.031522 C15.8089578,160.691742 8.03751765,169.705706 3.47500268,180.591197 L3.47500268,180.591197 C-1.08938447,191.478556 -1.80830353,205.139586 5.29663873,214.437454 L5.29663873,214.437454 C13.5061702,225.184729 28.4724175,226.35957 40.476868,232.065674 L40.476868,232.065674 C58.7550107,240.75651 69.8327296,259.892038 82.5355052,276.08953 L82.5355052,276.08953 C109.728993,310.763219 149.808731,335.393791 192.713595,338.653088 L192.713595,338.653088 C235.622205,341.912385 280.539668,322.067096 304.576652,284.957806 L304.576652,284.957806 C331.566072,243.291104 328.974968,185.73136 305.771106,141.670149 L305.771106,141.670149 C282.569117,97.6089378 241.609452,65.9648071 197.379081,45.787051 L197.379081,45.787051 C153.146837,25.6111628 109.189804,9.70691459 61.8029279,0 L61.8029279,0 L61.6269425,0.0429592141 Z',
      mainImg: '/img/07_Library/07-Cover.jpg',
      projectType: 'Illustration',
      keywords: 'Branding, Art Direction, Illustration',
      year: '2020',
      images: [
        '/img/07_Library/JPGS/1.jpg',
        '/img/07_Library/JPGS/2.jpg',
        '/img/07_Library/JPGS/3.jpg',
        '/img/07_Library/JPGS/4.jpg',
        '/img/07_Library/JPGS/5.jpg',
        '/img/07_Library/JPGS/6.jpg',
        '/img/07_Library/JPGS/7.jpg',
        '/img/07_Library/JPGS/8.jpg',
        '/img/07_Library/JPGS/9.jpg',
        '/img/07_Library/JPGS/10.jpg',
        '/img/07_Library/JPGS/11.jpg',
        '/img/07_Library/JPGS/13.jpg'
      ],
      links: [
        {
          title: 'Behance',
          link:
            'https://www.behance.net/gallery/97412775/UX-Branding-Design-Ilustraciones'
        }
      ]
    }
  ]
};
