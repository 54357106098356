import { TweenMax, Expo, Power3 } from 'gsap';
import Blob from '../../lib/util/canvas/Blob';
import BackgrounImage from './BackgroundImage';
import { drawImageProp } from '../../lib/util/canvas/CanvasDrawingUtils';
import Vector from '../../lib/util/math/Vector';

export const canvasStatus = {
  LOADING: 'loading',
  READY: 'ready',
  ERROR: 'error'
};

class BlobCarouselCanvas {
  constructor(
    canvasHtmlElement,
    projects,
    handleOverIn = () => {},
    handleOverOut = () => {}
  ) {
    this.canvas = canvasHtmlElement;
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight;
    this.context = this.canvas.getContext('2d');
    this.width = this.canvas.width;
    this.height = this.canvas.height;
    const maxRadius =
      this.width > this.height ? this.height / 3 : this.width / 3;
    const numPoints = maxRadius > 200 ? 8 : 5;
    this.blob = new Blob(
      this.context,
      this.width / 2,
      this.height / 2,
      maxRadius,
      numPoints,
      projects.length
    );
    this.projects = projects;
    this.backgroundImages = [];
    this.status = canvasStatus.LOADING;
    this.createBackgroundImages();
    this.shouldDrawImage = false;
    this.update();
    this.currentProject = 0;
    this.gradientBaseA = {
      backgroundColor: '#FFFFFF'
    };
    this.gradientBaseB = {
      backgroundColor: '#FFFFFF'
    };
    this.isProjectMode = false;
    this.handleOverIn = handleOverIn;
    this.handleOverOut = handleOverOut;
    TweenMax.to(this.gradientBaseA, 0.4, {
      backgroundColor: this.projects[0].gradient1
    });
    TweenMax.to(this.gradientBaseB, 0.4, {
      backgroundColor: this.projects[0].gradient2
    });
    this.isShowingImage = false;
    window.addEventListener('mousemove', this.handleMouseMove);
  }

  enterProject() {
    this.isProjectMode = true;
    this.setShouldDrawImage(true);
    this.blob.enterProject();
  }
  setCurrentProject(index) {
    TweenMax.to(this.gradientBaseA, 1, {
      backgroundColor: this.projects[index].gradient1,
      delay: 0.9
    });
    TweenMax.to(this.gradientBaseB, 1, {
      backgroundColor: this.projects[index].gradient2,
      delay: 0.9
    });

    if (this.isShowingImage) {
      TweenMax.to(this.backgroundImages[this.currentProject], 0.5, {
        opacity: 0,
        scale: 0.9,
        ease: Power3.easeOut,
        delay: 0.5
      });
      TweenMax.fromTo(
        this.backgroundImages[index],
        0.5,
        { opacity: 0, scale: 1.1, ease: Expo.easeOut, delay: 0.5 },
        { opacity: 1, scale: 1, delay: 0.5 }
      );
    }
    this.currentProject = index;
    this.blob.setIndex(index);
  }

  setShouldDrawImage(value) {
    if (value) {
      this.isShowingImage = true;
      TweenMax.fromTo(
        this.backgroundImages[this.currentProject],
        0.5,
        { opacity: 0, scale: 1.1, ease: Expo.easeOut },
        { opacity: 1, scale: 1 }
      );
    } else {
      this.isShowingImage = false;
      this.backgroundImages.forEach(image => {
        TweenMax.to(image, 0.3, {
          opacity: 0,
          scale: 0.9,
          ease: Power3.easeOut
        });
      });
    }
  }

  update() {
    if (this.status === canvasStatus.READY) {
      this.blob.update();
      this.draw();
    }
    window.requestAnimationFrame(() => {
      this.update();
    });
  }

  draw() {
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.context.globalAlpha = 1;
    this.context.save();
    this.blob.draw();
    this.context.clip();
    this.drawGradient();
    this.drawBackgroundImages();
    this.context.restore();
    // this.context.shadowOffsetX = 2;
    // this.context.shadowOffsetY = 2;
    // this.context.shadowBlur = 2;
    // this.context.fillStyle = '#000000';
    // this.context.fillRect(this.blob.location.x - 25, this.blob.location.y - 25, 50, 50);
  }

  drawGradient() {
    const gradient = this.context.createLinearGradient(
      0,
      0,
      this.width,
      this.height
    );
    gradient.addColorStop(0.4, this.gradientBaseA.backgroundColor);
    gradient.addColorStop(0.6, this.gradientBaseB.backgroundColor);
    this.context.fillStyle = gradient;
    this.context.fillRect(0, 0, this.width, this.height);
  }

  drawBackgroundImages() {
    this.backgroundImages.forEach(bg => {
      if (bg.opacity > 0) {
        this.context.globalAlpha = bg.opacity;
        const w = this.width * bg.scale;
        const h = this.height * bg.scale;
        const x = (this.width - w) / 2;
        const y = (this.height - h) / 2;
        drawImageProp(this.context, bg.image, x, y, w, h);
      }
    });
    this.context.globalAlpha = 1;
  }

  getLoadImagesPromises() {
    return this.projects.map((project, index) => {
      const uri = project.mainImg;
      return this.loadImage(uri);
    });
  }

  loadImage(uri) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        resolve(img);
      };
      img.onerror = () => {
        reject(new Error(`Error while loading image ${uri}`));
      };
      img.src = uri;
    });
  }

  createBackgroundImages() {
    Promise.all(this.getLoadImagesPromises())
      .then(result => {
        this.backgroundImages = result.map(img => new BackgrounImage(img));
        this.status = canvasStatus.READY;
      })
      .catch(error => {
        this.status = canvasStatus.ERROR;
        console.error(error);
      });
  }

  handleMouseMove = event => {
    const mousePos = new Vector(event.clientX, event.clientY);
    const centerPos = this.blob.location;
    const isInside = mousePos.substract(centerPos).length() < this.blob.radius;
    if (isInside) {
      this.handleOverIn(this);
    } else {
      this.handleOverOut(this);
    }
  };

  destroy() {
    window.removeEventListener('mousemove', this.handleMouseMove);
  }
}

export default BlobCarouselCanvas;
