import React, { useState } from 'react';
import styles from './Navigation.module.scss';
import './Navigation.scss';
import { Link, withRouter } from 'react-router-dom';

const Navigation = props => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const onLinkHover = () => {
    props.appState.changeState('isLinkHover', true);
  };

  const onLinkLeave = () => {
    props.appState.changeState('isLinkHover', false);
  };

  const onClickHome = () => {
    props.appState.changeState('isLinkHover', false);
    props.appState.changeState('blockLoader', true);
    props.appState.changeState('aboutDarkMode', false);
  };

  const onClick = () => {
    props.appState.changeState('aboutDarkMode', false);
  };

  return (
    <>
      {!props.appState.isLoadingHome && !props.appState.hideLinks && (
        <>
          <div className={`navigationMobile ${openMobileMenu && '-active'}`}>
            <div className={`navigationMobileInner`}>
              <div>iara grinspun</div>
              <div
                className={`hamburgerButton`}
                onClick={() => setOpenMobileMenu(o => !o)}
              />
            </div>
            <div className={`mobileMenu`}>
              <ul className={`primaryMenu`}>
                <li>
                  <Link to="/" onClick={() => setOpenMobileMenu(false)}>
                    home
                  </Link>
                </li>
                <li>
                  <Link to="/about" onClick={() => setOpenMobileMenu(false)}>
                    about {props.location.pathname === '/' && '& contact'}
                  </Link>
                </li>
                <li>
                  <Link to="/works" onClick={() => setOpenMobileMenu(false)}>
                    projects
                  </Link>
                </li>
                <li>
                  <Link to="/drafts" onClick={() => setOpenMobileMenu(false)}>
                    drafts
                  </Link>
                </li>
              </ul>
              {props.location.pathname === '/' && (
                <ul className={`secondaryMenu`}>
                  <li>
                    <a
                      href="https://www.behance.net/iaragrinspun"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      behance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dribbble.com/iaragrinspunn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      dribbble
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/iara-grinspun-17647478"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      linkedin
                    </a>
                  </li>
                </ul>
              )}
              <div className={`copyright`}>
                email me at:{' '}
                <div className={`copyrightCol`}>
                  <a
                    className={`copyrightEmail`}
                    href="mailto:iaragrinspun@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    iaragrinspun@gmail.com
                  </a>
                  <span className={`copyrightDate`}>2014 - 2020</span>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`${styles.wrapper} ${
              props.appState.aboutDarkMode ? 'dark-mode' : ''
            }`}
          >
            <div className={styles.wrapperTop}>
              <Link
                to="/"
                className={styles.topLeft}
                onMouseEnter={onLinkHover}
                onMouseLeave={onLinkLeave}
                onClick={onClickHome}
              >
                Iara Grinspun
              </Link>
              <Link
                to="/works"
                className={styles.topRight}
                onMouseEnter={onLinkHover}
                onMouseLeave={onLinkLeave}
                onClick={onClick}
              >
                Projects
              </Link>
            </div>
            <div className={styles.wrapperBottom}>
              <Link
                to="/about"
                className={styles.bottomLeft}
                onMouseEnter={onLinkHover}
                onMouseLeave={onLinkLeave}
              >
                About {props.location.pathname === '/' && '& contact'}
              </Link>
              {props.location.pathname === '/' && (
                <ul className={styles.secondaryMenu}>
                  <li>
                    <a
                      href="https://www.behance.net/iaragrinspun"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={onLinkHover}
                      onMouseLeave={onLinkLeave}
                    >
                      behance
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://dribbble.com/iaragrinspunn"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={onLinkHover}
                      onMouseLeave={onLinkLeave}
                    >
                      dribbble
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.linkedin.com/in/iara-grinspun-17647478"
                      target="_blank"
                      rel="noopener noreferrer"
                      onMouseEnter={onLinkHover}
                      onMouseLeave={onLinkLeave}
                    >
                      linkedin
                    </a>
                  </li>
                </ul>
              )}
              <Link
                to="/drafts"
                className={styles.bottomRight}
                onMouseEnter={onLinkHover}
                onMouseLeave={onLinkLeave}
                onClick={onClick}
              >
                Drafts
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(Navigation);
