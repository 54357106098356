import Vector from '../math/Vector';
import Point from './Point';
class Blob {
  static MIN_AREA_X = 150;
  static MAX_AREA_X = 150;
  static MIN_AREA_Y = 70;
  static MAX_AREA_Y = 70;
  constructor(
    context,
    x = 0,
    y = 0,
    maxRadius = 100,
    numPoints = 9,
    numSets = 1,
    color = '#000000',
    isInteractive = true,
  ) {
    this.context = context;
    this.radius = maxRadius;
    this.numPoints = numPoints;
    this.currentPoints = [];
    this.numSets = numSets;
    //Save sthe set of points so it has n number of point sets;
    this.pointsSets = [];
    this.angleOffset = (Math.PI * 2) / this.numPoints;
    this.color = color;
    this.location = new Vector(x, y);
    this.velocity = new Vector(0, 0);
    this.acceleration = new Vector(0, 0);
    this.mass = 10.0;
    this.currentAngle = 0;
    this.angleAcceleration = 0.1;
    this.angleVelocity = 0.001;
    this.blobAtractionPoint = new Vector(window.innerWidth / 2, window.innerHeight / 2);
    // this.maxSpeed = 3;
    this.maxSpeed = 0.0005;
    this.maxSpeed = 0.001;
    this.maxForce = 3;
    this.mousePos = new Vector(window.innerWidth / 2, window.innerHeight / 2);
    this.attractionPoint = new Vector(this.mousePos.x, this.mousePos.y);
    this.frameCount = 0;
    this.isProjectMode = false;
    if (isInteractive) {
      document.addEventListener('mousemove', this.handleMouseMove);
      document.addEventListener('mousedown', this.handleMouseDown);
    }
    this.init();
  }

  enterProject() {
    this.isProjectMode = true;
    this.currentPoints.forEach((point, i) => {
      point.setProjectMode(
        true,
        point.x + this.location.x - this.mousePos.x,
        point.y + this.location.y - this.mousePos.y,
      );
    });
  }

  setIndex(index) {
    const currentSet = this.pointsSets[index];
    this.currentPoints.forEach((point, i) => {
      point.setDestinationPosition(currentSet[i].origin.x, currentSet[i].origin.y);
    });
  }
  setColor(value) {
    this.color = value;
  }
  setMaxRadius(value) {
    if (this.radius === value) return;
    this.radius = value;
    this.init();
  }

  setNumpoints(value) {
    if (value === this.numPoints) return;
    this.numPoints = value;
    this.init();
  }

  init() {
    this.pointsSets = [];
    this.angleOffset = (Math.PI * 2) / this.numPoints;
    for (let p = 0; p < this.numSets; p++) {
      this.pointsSets[p] = [];
      for (let i = 0; i < this.numPoints; i++) {
        const theta = this.angleOffset * i;
        this.pointsSets[p].push(new Point(theta, this.radius));
        if (p === 0) {
          this.currentPoints.push(new Point(theta, this.radius));
        }
      }
    }
    this.setIndex(0);
  }
  update() {
    this.frameCount += 0.01;
    this.currentPoints.length > 0 && this.currentPoints.forEach(point => point.update());
    // forces motion
    const seekDestination = this.getSeekDestination();
    this.seek(seekDestination);
    this.velocity.add(this.acceleration);
    this.location.add(this.velocity);
    this.acceleration.multiply(0);

    const { x, y } = this.location;
    this.currentPoints.forEach((p, index) => {
      const diff = new Vector(p.x + x - this.mousePos.x, p.y + y - this.mousePos.y);
      const dist = diff.length();
      if (dist < 120) {
        p.setMouseAttraction(
          true,
          new Vector(this.mousePos.x - this.location.x, this.mousePos.y - this.location.y),
        );
      } else {
        p.setMouseAttraction(false);
      }
    });
  }

  draw() {
    if (this.currentPoints.length === 0) {
      return;
    }
    // const theta = this.velocity.heading() + Math.PI / 2;
    const { x, y } = this.location;
    this.currentAngle += 0.0001;
    this.context.save();
    this.context.fillStyle = this.color;
    this.context.translate(x, y);
    // this.context.rotate(theta);
    // this.context.rotate(Math.sin(this.currentAngle));
    this.context.beginPath();
    const lastPoint = this.currentPoints[this.numPoints - 1];
    const firstPoint = this.currentPoints[0];
    this.context.moveTo((lastPoint.x + firstPoint.x) / 2, (lastPoint.y + firstPoint.y) / 2);
    for (let i = 1; i < this.numPoints; i++) {
      const currentPoint = this.currentPoints[i];
      const nextPoint = this.getPreviousPoint(i);
      const controlX = (currentPoint.x + nextPoint.x) / 2;
      const controlY = (currentPoint.y + nextPoint.y) / 2;
      this.context.quadraticCurveTo(nextPoint.x, nextPoint.y, controlX, controlY);
    }
    this.context.quadraticCurveTo(
      lastPoint.x,
      lastPoint.y,
      (lastPoint.x + firstPoint.x) / 2,
      (lastPoint.y + firstPoint.y) / 2,
    );
    this.context.strokeStyle = 'black';
    this.context.lineWidth = 1;
    this.context.closePath();
    this.context.fill();
    this.context.restore();
    // this.drawControlPoints();
    // this.drawPoints();
  }

  drawPoints() {
    this.context.save();
    this.context.fillStyle = '#000000';
    this.context.font = '8px Arial';
    const { x, y } = this.location;
    // this.context.translate(x, y);
    for (let i = 0; i < this.numPoints; i++) {
      const currentPoint = this.currentPoints[i];
      const diff = new Vector(
        currentPoint.x + x - this.mousePos.x,
        currentPoint.y + y - this.mousePos.y,
      ).length();
      this.context.fillRect(currentPoint.x + x, currentPoint.y + y, 8, 8);
      this.context.fillText(
        `${i} - ${Math.round(diff)}, ${Math.round(currentPoint.x + this.location.x)}, ${Math.round(
          currentPoint.y + this.location.y,
        )}`,
        currentPoint.x + x,
        currentPoint.y + y,
      );
    }
    this.context.fillText(
      `${Math.round(this.mousePos.x)}, ${Math.round(this.mousePos.y)}`,
      this.mousePos.x,
      this.mousePos.y,
    );
    this.context.restore();
  }

  drawControlPoints() {
    this.context.save();
    this.context.fillStyle = '#FF0000';
    const { x, y } = this.location;
    this.context.translate(x, y);
    for (let i = 0; i < this.numPoints; i++) {
      const currentPoint = this.currentPoints[i];
      const nextPoint = this.getPreviousPoint(i);
      const controlX = (currentPoint.x + nextPoint.x) / 2;
      const controlY = (currentPoint.y + nextPoint.y) / 2;
      this.context.fillRect(controlX, controlY, 5, 5);
    }
    this.context.restore();
    this.context.translate(0, 0);
  }

  getPreviousPoint(index) {
    if (index >= this.numPoints) {
      return this.currentPoints[0];
    } else if (index === 0) {
      return this.currentPoints[this.numPoints - 1];
    }
    return this.currentPoints[index - 1];
  }

  applyForce(force) {
    force.divide(this.mass);
    this.acceleration.add(force);
  }

  handleMouseMove = event => {
    const { clientX, clientY } = event;
    this.mousePos.x = clientX;
    this.mousePos.y = clientY;

    // this.currentPoints.forEach(point => point.setDestinationPosition(screenX, screenY));
  };
  handleMouseDown = event => {
    // this.currentPoints.forEach((p, index) => {
    //   const diff = new Vector(p.x + x - this.mousePos.x, p.y + y - this.mousePos.y);
    //   const dist = diff.length();
    //   if (dist < 100) {
    //     p.setMouseAttraction(
    //       true,
    //       new Vector(this.mousePos.x - this.location.x, this.mousePos.y - this.location.y),
    //     );
    //   } else {
    //     p.setMouseAttraction(false);
    //   }
    // });
  };

  seek(target) {
    const desired = new Vector(target.x, target.y);
    desired.substract(this.location);
    desired.multiply(this.maxSpeed);
    desired.substract(this.velocity);
    desired.limit(this.maxForce);
    this.applyForce(desired);
  }
  /**
   * Check if it should follow Mouse or not
   */
  getSeekDestination() {
    let force = this.mousePos;
    if (
      this.mousePos.x < Blob.MIN_AREA_X ||
      this.mousePos.x > window.innerWidth - Blob.MAX_AREA_X ||
      this.mousePos.y < Blob.MIN_AREA_Y ||
      this.mousePos.y > window.innerHeight - Blob.MAX_AREA_Y
    ) {
      force = new Vector(window.innerWidth / 2, window.innerHeight / 2);
      const random = new Vector(Math.cos(this.frameCount) * 100, Math.sin(this.frameCount) * 100);
      force.add(random);
    }
    return force;
  }
}

export default Blob;
