import React from 'react';
import { motion } from 'framer-motion';
import TrackVisibility from 'react-on-screen';

const MotionChildren = ({ children, ...props }) => {
  const item = (function() {
    switch (props.animation) {
      case 'slide-up':
        return {
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 80 }
        };

      default:
        return {
          visible: { opacity: 1, y: 0 },
          hidden: { opacity: 0, y: 50 }
        };
    }
  })();

  return (
    <motion.div
      variants={item}
      transition={{ type: 'spring', stiffness: 50 }}
      {...props}
    >
      {children}
    </motion.div>
  );
};

const MotionWrapper = ({ trackClassName, children, ...props }) => {
  const list = {
    visible: {
      transition: {
        when: 'beforeChildren',
        staggerChildren: 0.2
      }
    }
  };
  return (
    <TrackVisibility className={trackClassName} partialVisibility once>
      {({ isVisible }) =>
        isVisible ? (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={list}
            {...props}
          >
            {children}
          </motion.div>
        ) : (
          <div style={{ width: '100%' }} />
        )
      }
    </TrackVisibility>
  );
};

export { MotionChildren, MotionWrapper };
